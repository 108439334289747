export default defineNuxtRouteMiddleware((to, _from) => {
  const user = useStrapiUser();

  if (user.value) {
    abortNavigation();

    // if (to.fullPath === _from.fullPath) {
    //   // If first coming to login paths will be the same, redirect to account instead
    //   return navigateTo('/account');
    // }

    // return navigateTo(_from.fullPath);

    // Return to account, but keep old code
    return navigateTo('/account');
  }
})
